<template>
  <GuestLayout title="Full-stack A/B testing made easy">
    <div class="bg-gradient-to-b from-white to-slate-200">
      <Hero />
    </div>

    <PrimaryFeatures />

    <section class="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8 border-y">
      <div class="absolute inset-0 -z-10 bg-[radial-gradient(20rem_50rem_at_top,theme(colors.slate.200),white)] opacity-20" />
      <div class="absolute inset-y-0 right-1/2 -z-10 mr-18 w-[200%] origin-bottom-left skew-x-[-40deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-9 xl:origin-center" />
      <div class="mx-auto max-w-2xl lg:max-w-4xl text-center">
        <h2 class="marketing-heading font-normal">Why Checkmango?</h2>
        <figure class="mt-10">
          <blockquote class="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p>“The perfect solution for a cost-effective, no-fuss and developer-friendly A/B testing platform. We shall be using Checkmango for many years to come.”</p>
          </blockquote>
          <figcaption class="mt-10">
            <img class="mx-auto size-10 rounded-full" src="https://ui-avatars.com/api/?name=Ollie+Reardon&background=10b981&color=fff" alt="" />
            <div class="mt-4 flex items-center justify-center space-x-3 text-base">
              <div class="font-semibold text-gray-900">Ollie R.</div>
              <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" class="fill-gray-900">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <a href="https://euautomation.com?ref=checkmango" class="text-gray-600 hover:underline hover:text-emerald-600">EU Automation</a>
            </div>
          </figcaption>
        </figure>
      </div>
    </section>

    <section id="features" class="relative overflow-hidden bg-white py-24 text-slate-900">
      <div class="container">
        <header class="text-center">
          <h2 class="mx-auto max-w-xl text-center font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">Packed With Features</h2>
        </header>

        <div class="mt-12 grid items-start gap-12 md:mt-24 md:grid-cols-3">
          <header class="prose-lg">
            <div class="flex h-14 w-14 items-center justify-center rounded-xl bg-white text-emerald-500 shadow-xl shadow-emerald-500/25 ring-1 ring-black/5">
              <CursorArrowRaysIcon class="size-10" />
            </div>

            <h3 class="font-semibold">Event Types</h3>

            <p class="mt-2">Track the conversion rate of each variant using events.</p>

            <ul class="p-0">
              <li class="pl-0"><strong>Unique</strong> - Counts one conversion per participant (created account, logged in)</li>
              <li class="pl-0"><strong>Count</strong> - Counts multiple conversions per participant (clicks, views, downloads)</li>
            </ul>
          </header>

          <header class="prose-lg">
            <div class="flex h-14 w-14 items-center justify-center rounded-xl bg-white text-yellow-500 shadow-xl shadow-yellow-500/25 ring-1 ring-black/5">
              <ClockIcon class="size-10" />
            </div>

            <h3 class="font-semibold">Participant Timeline</h3>

            <p class="mt-2">See a timeline of your participants actions. This includes when participants were enrolled into an experiment, which variant they were enrolled into and what events were triggered.</p>
          </header>

          <header class="prose-lg prose-emerald">
            <div class="flex h-14 w-14 items-center justify-center rounded-xl bg-white text-rose-500 shadow-xl shadow-rose-500/25 ring-1 ring-black/5">
              <CodeBracketIcon class="size-10" />
            </div>

            <h3 class="font-semibold">JSON API</h3>

            <p class="mt-2">
              Start A/B testing your application or website with Checkmango's super-charged JSON API. The API is
              <a href="https://developers.checkmango.com" class="font-semibold text-emerald-500">fully documented</a> and available in OpenAPI formats.
            </p>
          </header>
        </div>
      </div>
    </section>

    <section id="prove" class="relative overflow-hidden bg-slate-100 py-24 text-slate-900">
      <div class="container relative text-center">
        <h2 class="mx-auto max-w-xl text-center font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">Built with developers in mind</h2>
        <div class="mt-12 md:mt-24 grid gap-12 sm:grid-cols-3">
          <article class="prose-lg">
            <figure class="flex aspect-1 items-center justify-center rounded-md bg-[#8892bf] text-white shadow-xl shadow-[#8892bf]/20 ring-1 ring-[#8892bf]/10">
              <img src="/img/marketing/php.svg" class="text-white w-48" alt="PHP" />
            </figure>

            <h3 class="font-semibold">PHP</h3>

            <p class="mt-2">The <a href="https://packagist.org/packages/checkmango/php-sdk" class="font-semibold underline">Checkmango PHP SDK</a> integrates into applications with Laravel, Magento, WordPress and more.</p>
          </article>

          <article class="prose-lg">
            <figure class="flex aspect-1 items-center justify-center rounded-md bg-[#f7df1e] text-white shadow-xl shadow-[#f7df1e]/20 ring-1 ring-[#f7df1e]/10">
              <img src="/img/marketing/js.svg" class="w-24" alt="JavaScript" />
            </figure>

            <h3 class="font-semibold">JavaScript</h3>

            <p class="mt-2"><a href="https://npmjs.com/package/@checkmango/checkmango.js" class="font-semibold underline">Checkmango.js</a> provides convenient access to the Checkmango API for applications written in JavaScript.</p>
          </article>

          <article class="prose-lg">
            <figure class="flex aspect-1 items-center justify-center rounded-md bg-teal-500 text-white shadow-xl shadow-teal-500/20 ring-1 ring-teal-500/10">
              <CodeBracketIcon class="text-white w-24" />
            </figure>

            <h3 class="font-semibold">Integrate</h3>

            <p class="mt-2">No SDK? Use the <a href="https://developers.checkmango.com" class="font-semibold underline">Developer API</a> to build your own integrations.</p>
          </article>
        </div>
      </div>
    </section>

    <RegisterInterest />

    <div id="pricing" class="relative overflow-hidden bg-white py-24 text-slate-900">
      <div class="container relative">
        <header class="flex flex-col items-center justify-center text-center">
          <h2 class="marketing-headline">Pricing</h2>

          <p class="marketing-text mt-2">Checkmango is priced by ingest API requests per month.<br />Choose yearly billing and <strong class="font-semibold">save 10%</strong>.</p>

          <div class="mt-8 flex justify-center">
            <RadioGroup v-model="frequency" class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-slate-200">
              <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>
              <RadioGroupOption v-for="option in frequencies" :key="option.value" v-slot="{ checked }" as="template" :value="option">
                <div :class="[checked ? 'bg-emerald-500 text-white' : 'text-slate-500', 'cursor-pointer rounded-full px-2.5 py-1']">
                  <span>{{ option.label }}</span>
                </div>
              </RadioGroupOption>
            </RadioGroup>
          </div>
        </header>

        <div class="isolate mx-auto mt-10 flex max-w-md flex-col md:max-w-2xl lg:flex-row lg:max-w-4xl xl:mx-0 xl:max-w-none gap-4">
          <Plan v-for="plan in plans" :key="plan.id" :frequency="frequency" :tier="plan" :marketing="true" />

          <div class="p-2 pl-0">
            <div class="rounded-md bg-white p-6 shadow-xl ring-1 ring-white/10 sm:mx-0">
              <h3 id="custom" class="text-xl font-semibold leading-8 text-slate-900">Custom</h3>
              <p class="mt-4 leading-6 text-slate-600">Need something bespoke? Let's work together.</p>
              <p class="mt-4 flex items-baseline gap-x-1">
                <span class="text-2xl font-bold tracking-tight text-slate-900">Custom</span>
              </p>
              <a href="mailto:support@checkmango.com?subject=Custom Pricing" aria-describedby="custom" class="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-emerald-500 ring-1 ring-inset ring-emerald-200 hover:ring-emerald-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Faqs />

    <section id="trustedby" class="relative overflow-hidden bg-white py-24 text-slate-900">
      <div class="container relative">
        <div class="bg-white py-8 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
              <div class="mx-auto w-full max-w-xl lg:mx-0">
                <h2 class="text-3xl marketing-heading">🌍 Used by teams around the world</h2>
                <p class="mt-6 prose-lg">You're in good company. We're proud to be trusted by companies of all sizes.</p>
                <div class="mt-8 flex items-center gap-x-4">
                  <Link :href="route('register')" class="button button--success">Create account</Link>
                  <a href="mailto:support@checkmango.com" class="button button--secondary">Contact us <span aria-hidden="true">&rarr;</span></a>
                </div>
              </div>
              <div class="mx-auto grid w-full max-w-xl grid-cols-1 sm:grid-cols-2 items-center gap-y-8 sm:gap-y-12 lg:mx-0 lg:max-w-none lg:pl-8">
                <EuAutomationLogo class="max-h-16 w-full object-contain object-center" style="height: 52px" />
                <CachetLogo class="max-h-16 w-full object-contain object-center" style="height: 52px" />
                <FeadLogo class="max-h-16 w-full object-contain object-center" style="height: 52px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </GuestLayout>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import { defineAsyncComponent } from 'vue'
const GuestLayout = defineAsyncComponent(() => import('@/Layouts/GuestLayout.vue'))
import { CursorArrowRaysIcon, ClockIcon, CodeBracketIcon } from '@heroicons/vue/24/outline'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import Plan from './Partials/Plan.vue'
import Hero from '@/Pages/Marketing/Partials/Hero.vue'
import Faqs from '@/Pages/Marketing/Partials/Faqs.vue'
import PrimaryFeatures from '@/Pages/Marketing/Partials/PrimaryFeatures.vue'
const RegisterInterest = defineAsyncComponent(() => import('@/Components/RegisterInterest.vue'))
import EuAutomationLogo from '@/Pages/Marketing/Logos/EuAutomationLogo.vue'
import CachetLogo from '@/Pages/Marketing/Logos/CachetLogo.vue'
import FeadLogo from '@/Pages/Marketing/Logos/FeadLogo.vue'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'yearly', label: 'Yearly', priceSuffix: '/year' },
]

export default {
  components: {
    FeadLogo,
    CachetLogo,
    EuAutomationLogo,
    RegisterInterest,
    PrimaryFeatures,
    Faqs,
    Hero,
    Plan,
    CursorArrowRaysIcon,
    ClockIcon,
    CodeBracketIcon,
    GuestLayout,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    Link,
  },
  props: {
    plans: Object,
  },
  data() {
    return {
      frequencies: frequencies,
      frequency: frequencies[0],
    }
  },
  methods: {
    formatPrice(amount) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(amount)
    },
  },
}
</script>
